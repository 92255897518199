body {
  margin: 0;
  padding: 0;
  font-family: VaillantApplianceInterface-Book -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F1F1
}

code {
  font-family: VaillantApplianceInterface-Book, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'VaillantApplianceInterface-Book';
  font-style: normal;
  src: local('VaillantApplianceInterface-Book'), url(./Fonts/VaillantApplianceInterface-Book.ttf) format('truetype');
}
