.App {
  overflow: hidden;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

#root {
  text-align: center;
  align-items: center;
  background-color: #eaebee;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 108px auto 109px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'main'
    'footer';
}
