.rotating {
    width: 50px;
    height: 50px;
    animation-name: ckw;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.activity-spinner-background{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: black;
}
